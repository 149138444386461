<template>
    <div class="container">
        <div class="contentBox">
            <!-- 头部布局盒子 -->
            <div class="headerBox">
                <Pc_Header></Pc_Header>
            </div>


            <!-- 主题内容盒子 -->
            <div class="bodyBox">
                    <div class="swiper-container" style="width: 100%;" ref="floorSwiper">
                        <div class="swiper-wrapper">
                            <div class="swiper-slide">
                                <img class="imgCss" :src="imgList[0]">
                            </div>
                            <div class="swiper-slide">
                                <img class="imgCss2" :src="imgList[1]">
                            </div>
                            <div class="swiper-slide">
                                <img class="imgCss" :src="imgList[2]">
                            </div>
                        </div>
                    </div>
            </div>

            <div class="footBox">
                <!-- 背景图片 -->
                <img :src="bcImg">
                
                <!-- <div class="logBox">
                    <img :src="imags.IconImg">
                    <p>iSPEAK</p>
                </div> -->

                <!-- 二维码位置 -->
                <div class="codeContainer">
                    <div class="codeBox">
                        <p>App Store</p>
                        <img :src="imags.code1 == ''? imags.backWhiteColor: imags.code1">
                    </div>
                    <div class="codeBox">
                        <p>应用商店</p>
                        <img :src="imags.code2 == ''? imags.backWhiteColor: imags.code2">
                    </div>
                    <!-- <div style="margin-right: 20px;">
                        <button class="btnStyle" @click="clickBtn">苹果下载</button>
                    </div>
                    <div>
                        <button class="btnStyle" @click="clickBtn">安卓下载</button>
                    </div> -->
                </div>

                <!-- 关于我们 -->
                <div class="aboutBox">
                    <h2>欢迎关注i说英语微信公众号!</h2>
                    <p>公司简介：深圳市有点智能信息技术有限公司，是一家创办于2023年的教育科技公司。创始团队来自北京大学、复旦大学等一流名校，并拥有丰富的人工智能和互联网行业从业经验，团队致力于利用先进的人工智能技术，为用户打造创新的学习工具和解决方案。推动科技与教育的融合，助力实现知识、技能的普惠，帮助社会把握人工智能时代的机会与挑战。</p>
                    <p>地址：深圳市宝安区新安街道大浪社区新安三路一巷51号</p>
                    <p>联系电话：0755-86716634</p>
                </div>

                <div class="recordInfoBox">
                        <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024235969号-1</a><a>|</a><a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2024235969号-2A</a>
                </div>

                <div class="infoBox">
                        <img :src="imags.recordIcon">
                        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002003685" target="_blank" rel="noreferrer">粤公网安备44030002003685</a>
                </div>

                <div class="copyright">
                    <p>Copyright © 2023 - 2024 深圳市有点智能信息技术有限公司</p>
                </div>

            </div>

        </div>
        

    </div>
</template>

<script>
import Pc_Header from '@/components/pc_home/pc_header.vue'
import banner1 from '@/assets/images/icons/banner1.png'
import banner2 from '@/assets/images/icons/banner2.png'
import banner3 from '@/assets/images/icons/banner3.png'
import pcBack from '@/assets/images/icons/pc_back.png'
import backWhiteColor from '@/assets/images/icons/backgroundColorWhite.png'
import fIcon from '@/assets/images/icons/f_icon.png'
import inIcon from '@/assets/images/icons/in_icon.png'
import vxIcon from '@/assets/images/icons/vx_icon.png'
import wbIcon from '@/assets/images/icons/wb_icon.png'
import recordIcon from '@/assets/images/icons/record_Icon.png'
import bcImg from '@/assets/images/icons/pc_back.png'
import Swiper from "swiper";
import IconImg from '@/assets/images/icons/iconDownLoad.png';
import isoQrCode from '@/assets/images/icons/iso_qrcode.png';
import apkQrCode from '@/assets/images/icons/apk_qrcode.png';

export default {
    components:{
        Pc_Header
    },
    data(){
        return {
            screenWidth: 0,
            screenHeight: 0,
            imgList: [banner1,banner2,banner3],
            iconList: [inIcon,fIcon,vxIcon,wbIcon],
            imags: {
                pcBack: pcBack,
                backWhiteColor: backWhiteColor,
                code1: isoQrCode,
                code2: apkQrCode,
                recordIcon: recordIcon,
                IconImg: IconImg
            },
            swiper: null,
            bcImg: bcImg
        }
    },
    created(){
        // 获取电脑的系统宽度
        this.screenWidth = parseInt(window.innerWidth)
        this.screenHeight = parseInt(window.innerHeight)

        window.scrollTo((document.body.scrollWidth - window.innerWidth) / 2,0);
    },
    watch:{
    list:{
      immediate: true,
      handler(){
        this.$nextTick(()=>{
          new Swiper(this.$refs.floorSwiper,{
            loop: true,
            autoplay: {
                delay: 3000, // 切换间隔时间为3000毫秒，即3秒
                // 如果你还想停止 autoplay 当用户交互，可以添加以下属性
                disableOnInteraction: false,
            }
          })
        })
      }
    }
  },
    methods: {
        clickBtn(){
            const agent = window.navigator.userAgent;
            const isIso = /iPad|iPhone|Mac/.test(agent)
            if(isIso){
              let a = document.createElement('a')
               a.href = "https://apps.apple.com/cn/app/i%E8%AF%B4%E8%8B%B1%E8%AF%AD/id6497169872"
               a.click();
            }else{
               let a = document.createElement('a')
               a.href = "https://oss.ispeak.fun/download/android/ispeak-latest.apk"
               a.click();
               // window.reload();
            }
        },
        toUrl(){
            window.location.href= 'https://beian.miit.gov.cn/'
        }
    }
}
</script>

<style scoped>
    .container{
        position: absolute;
        left: 0px;
        top: 0px;
        width: 1690px;
        height: 900px;
        margin: 0 auto;
        /* border: 1px solid black; */
    }

    .contentBox{
        width: 100%;
        /* border: 1px solid red; */
        position: relative;
    }

    
    .contentBox > .headerBox{
        width: 80%;
        /* border: 1px solid black; */
        position: relative;
        left: 10%;
        z-index: 100;
    }

    .contentBox > .bodyBox{
        /* border: 1px solid black; */
        width: 100%;
        height: 60%;
        margin-top: 1%;
        position: relative;
        z-index: 1;
    }
    
    .imgCss{
        width: 1690px;
        height: 560px;
        position: relative;
    }

    .imgCss2 {
        width: 1690px;
        height: 560px;
        position: relative;
    }

    
    .footBox{
        width: 100%;
        height: 450px;
        z-index: 1000;
        position: relative;
        margin-top: -105px;
        /* border: 1px solid black; */
    }

    .footBox > img{
        width: 100%;
        height: 450px;
        z-index: 1;
    }


    .recordBox{
      position: absolute;
      top: 85%;
      left: 48%;
      width: 240px;
      /* border: 1px solid black; */
      display: flex;
      justify-content: space-evenly;
    }

    .recordBox > img{
      width: 25px;
      height: 25px;
    }

    .recordBox > a{
      text-decoration: none;
      color: white;
      font-size: 14px;
      margin-top: 3px;
    }

    .codeContainer{
        width: 420px;
        height: 180px;
        position: absolute;
        top: 160px;left: 20%;
        /* border: 1px solid black; */
        z-index: 100;
        display: flex;
        justify-content: space-evenly;
        justify-items: center;
    }

    .codeContainer button:hover{
        background: rgb(249, 108, 21);
    }


    .logBox{
        display: flex;
        justify-content: space-evenly;
        justify-self: center;
        width: 180px;
        position: absolute;
        top: 170px;
        left: 19%;
        color: white;
        font-size: 18px;
    }
    .logBox > img{
        width: 60px;
        height: 60px;
    }

    .btnStyle{
        background-color: rgb(0, 0, 0);
        border: none;
        color: white;
        padding: 10px;
        font-size: 18px;
        width: 180px;
        border-radius: 50px;
        z-index: 1200;
    }

    .codeContainer > .codeBox{
        width: 40%;
        margin-top: 15px;
        height: 80%;
        /* border: 1px solid black; */
        /* border-radius: 10px; */
    }

    .codeBox > p{
        color: white;
        margin-top: 0px;
        margin-left: 5px;
    }

    .codeBox > img{
        width: 100px;
        height: 100px;
        border-radius: 15px;
        border: 1px solid white;
        padding: 5px;
        margin-top: -10px;
    }

    .aboutBox{
        width: 800px;
        right: 5%;
        height: 280px;
        /* border: 1px solid black; */
        position: absolute;
        top: 120px;
        color: white;
    }

    .aboutBox > p{
        margin-top: -5px;
        font-size: 15px;
    }

    .aboutBox > h2{
        color: white;
        font-family: Nuntio;
    } 

    .infoBox{
        width: 300px;
        position: absolute;
        bottom: 48px;
        left: 40%;
        display: flex;
        justify-content: space-evenly;
        justify-content: center;
        /* border: 1px solid black; */
        color: white;
        font-size: 14px;
    }

    .copyright{
        /* width: 300px; */
        position: absolute;
        bottom: 5px;
        left: 38%;
        color: white;
        font-size: 14px;
    }

    .infoBox > img{
        margin-right: 5px;
        width: 25px;
        height: 25px;
    }

    .infoBox > a{
        margin-top: 3px;
        text-decoration: none;
        color: white;
    }

    .recordInfoBox{
        position: absolute;
        top: 78%;
        left: 39%;
        width: 350px;
        font-size: 14px;
        display: flex;
        justify-content: space-evenly;
        /* border: 1px solid black; */
    }

    .recordInfoBox > a{
        text-decoration: none;
        color: white;
    }



    /* swiper切换按钮样式 */
    .swiper-button-next{
        margin-right: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        width: 50px;
        height: 130px;
    }

    .swiper-button-prev{
        margin-left: 20px;
        background-color: rgba(0, 0, 0, 0.2);
        color: white;
        width: 50px;
        height: 130px;
    }

    .swiper-button-next:hover,.swiper-button-prev:hover{
        background-color: rgba(0, 0, 0, 0.6);
    }

</style>