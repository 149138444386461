<template>
    <div class="container">
        <div class="titleBox">
            <p class="bigTitle">{{ bigTitle }}</p>
            <p class="thinTitle">{{ thinTitle }}</p>
        </div>
        <div class="imgBox3">
            <img :src="imgList[0]"/>
        </div>
    </div>
</template>

<script>
import phoneImg from '@/assets/images/icons/phone.png'
export default {
    data(){
        return {
            bigTitle: '三大学习利器',
            thinTitle: '权威发音评测，灵活自由对话，智能在线答疑',
            imgList:[phoneImg],
        }
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: relative;
        top: -2%;
        text-align: center;
    }

    .bigTitle{
        width: 95%;
        margin-bottom: 5%;
        position: relative;
        left: 2%;
        font-size: 1.8em;
        font-weight: bold;
    }

    .thinTitle{
        width: 96%;
        position: relative;
        left: 2%;
        top: 10%;
        font-size: 1em;
    }

    .titleBox{
        width: 100%;
        position: absolute;
        top: 3%;
        /* border: 1px solid pink; */
    }

    .hidePage{
        position: fixed;
        top: 15%;right: 5%;
        z-index: 10;
    }

   /* 
   
   */

.imgBox3{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* border: 1px solid black; */
}

.imgBox3 > img{
  position: absolute;
  top: 12%;
  transform: translate(-50%,45%);
  width: 360px;
  height: 340px;
}
</style>
