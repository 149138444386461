<template>
    <div class="footBox">

      <template>
          <!-- 填写表单信息盒 -->
          <div v-show="!isReceive" class="formBox">
            <div class="formBox-titleBox">
                <p>恭喜您</p>
                <p>请填写注册手机号及邀请码</p>
            </div>
            <div class="formBox-input-submit">
                <input type="text" v-model="inputs.phoneValue" @focus="removeTxt" @blur="checkTxt" class="input_phone" :placeholder="inputs.placeTxt">
            </div>

            <div class="formBox-input-submit">
                <input type="text" v-model="inputs.codeValue" @focus="removeCode" @blur="checkCode" class="input_phone" :placeholder="inputs.placeCodeTxt">
                <img class="button" @click="getPacks" :src="imgs.getButton">
            </div>
          </div>

          <!-- 显示下载路径 -->
          <div v-show="isReceive" class="formBox">
            <div class="formBox-titleBox">
                <p>{{ promptMessage }}</p>
            </div>
            <div class="appIcon">
              <img :src="imgs.iconDownLoadIcon">
            </div>
            <div class="downLoadButton" @click="downLoadButton()">
              <img :src="imgs.downLoadButtonIcon">
            </div>
          </div>
      </template>

      <!-- 背景图片iSPEAK -->
      <div class="titlePic">
        <!-- <p class="callMethods">{{ titles.callMethodsTxt }}</p> -->
        <p class="copys">Copyright © 2023 - 2024 <br> 深圳市有点智能信息技术有限公司</p>
        <br>
        <div class="idBox">
          <p class="id" @click="toPageUrl">{{ titles.id }}</p> 
          <p>|</p>
          <p class="id2" @click="toPageUrl">{{ titles.id2 }}</p>
        </div>
      </div>
  
    </div>
  </template>
  
  <script>
  import backgroundWhiteColor from '@/assets/images/icons/backgroundColorWhite.png'
  import fIcon from '@/assets/images/icons/f_icon.png'
  import inIcon from '@/assets/images/icons/in_icon.png'
  import vxIcon from '@/assets/images/icons/vx_icon.png'
  import wbIcon from '@/assets/images/icons/wb_icon.png' 
  import titlePic from '@/assets/images/icons/iSpeakImg.png'
  import titlePicGif from '@/assets/images/gifs/dinosaur-a.gif'
  import getButton from '@/assets/images/icons/button.png'
  import iconDownLoadIcon from '@/assets/images/icons/iconDownLoad.png'
  import downLoadButtonIcon from '@/assets/images/icons/downLoadButton.png'
  import axios from 'axios'
  export default {
    props:['inviter_id'],
    data(){
      return {
        inputs:{
          placeTxt: '请输入手机号',
          placeCodeTxt: '请输入邀请码',
          phoneValue: '',
          codeValue: ''
        },
        promptMessage: '',
        isReceive: false,
        titles:{
          title1: 'App Store',
          title2: '应用商店',
          callMethodsTxt: '欢迎关注i说英语微信公众号!',
          content: 'We are on a mission to make language learning irresistibly fun, accessible, and effective, ensuring every tap and swipe is a step towards a new world of words for your child.',
          content2: 'Copyright © 2023 - 2024 深圳市有点智能信息技术有限公司',
          id: "粤ICP备2024235969号-1",
          id2: '粤ICP备2024235969号-2A'
        },
        imgs:{
          codeImg1:'',
          codeImg2:'',
          backgroundWhiteColor: backgroundWhiteColor,
          titleImg: titlePic,
          titleImgGif: titlePicGif,
          icons:[fIcon,inIcon,vxIcon,wbIcon],
          getButton: getButton,
          iconDownLoadIcon: iconDownLoadIcon,
          downLoadButtonIcon: downLoadButtonIcon
        }
      }
    },
    methods:{
      toPageUrl(){
        window.location.href = 'https://beian.miit.gov.cn/'
      },
      removeTxt(){
        this.inputs.placeTxt = '';
      },
      removeCode(){
        this.inputs.placeCodeTxt = '';
      },
      checkTxt(){
        if(this.inputs.phoneValue == '' || this.inputs.phoneValue.length === 0){
          this.inputs.placeTxt = '请输入手机号'
        }
      },
      checkCode(){
        if(this.inputs.codeValue == '' || this.inputs.codeValue.length === 0){
          this.inputs.placeCodeTxt = '请输入邀请码'
        }
      },
      downLoadButton(){
        const agent = window.navigator.userAgent;
        const isIso = /iPad|iPhone/.test(agent)
        if(isIso){
          let a = document.createElement('a')
           a.href = "https://apps.apple.com/cn/app/i%E8%AF%B4%E8%8B%B1%E8%AF%AD/id6497169872"
           a.click();
        }else{
           let a = document.createElement('a')
           a.href = "https://oss.ispeak.fun/download/android/ispeak-latest.apk"
           a.click();
        }
      },
      getPacks(){
        axios({
                url:'https://api.ispeak.fun/share/coupon',
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json',
                    'Access-Control-Allow-Origin': '*'
                },
                data: {
                  "phone": this.inputs.phoneValue,
                  "code": this.inputs.codeValue
                }
            }).then(res=>{
                const message = res.data.message
                console.log('message = ' + message)
                this.showMessage(message);  
            })
      },
      showMessage(message){
        switch(message){
                case '优惠已经领取':
                  this.promptMessage = '优惠已经领取';
                  this.isReceive = true;
                  break;
                case '优惠券不存在或已过期':
                  this.promptMessage = '优惠券不存在或已过期';
                  this.isReceive = true;
                  break;
                case '优惠券领取成功':
                  this.promptMessage = '优惠券领取成功';
                  this.isReceive = true;
                  break;
        }
      }
    }
  }
  </script>
  
  <style scoped>
      .message{
        background: red;
      }

      .footBox{
        width: 100%;
        height: 100%;
        background-image: url('../../assets/images/icons/backgroundPic@1x.png');
        background-repeat: no-repeat;
        background-size: cover;
        position: relative;
        display: flex;
        justify-content: center;
        justify-items: center;
      }

      .formBox{
        width: 90%;
        position: absolute;
        top: 15%;
        left: 5%;
        /* border: 1px solid black; */
        height: 260px;
        z-index: 1000;
      }

      .formBox .appIcon{
        margin: 0 auto;
        position: relative;
        top: 5%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        justify-items: center;
      }

      .formBox .downLoadButton{
        margin: 0 auto;
        position: relative;
        top: 10%;
        /* border: 1px solid black; */
        display: flex;
        justify-content: center;
        justify-items: center;
      }

      .appIcon>img{
        width: 90px;
        height: 90px;
        border-radius: 15%;
      }

      .downLoadButton > img{
        width: 200px;
        height: 50px;
      }

      .formBox-titleBox{
        position: relative;
        top: 0%;
        width: 100%;
        text-align: center;
        margin-bottom: 10px;
      }

      .formBox-titleBox > p:nth-of-type(1){
        color: #ffd038;
        font-size: 2.3em;
      }

      .formBox-titleBox > p:nth-of-type(2){
        color: white;
        font-size: 1.8em;
      }

      .formBox-titleBox > p{
        margin-top: 0px;
        margin-bottom: 10px;
        font-family: rtxp;
        text-shadow: 0px 2px 1px rgb(56, 56, 56);
      }

      .formBox-input-submit{
        position: relative;
        top: 3%;
        display: flex;
        flex-direction: column;
      }

      .formBox-input-submit > .input_phone{
        display: block;
        margin-top: 2px;
        width: 280px;
        height: 45px;
        text-align: center;
        margin: 0 auto;
        margin-bottom: 10px;
        font-size: 18px;
        border: white 1px solid;
        border-radius: 15px;
        z-index: 100;
      }

      .formBox-input-submit > .button{
        width: 250px;
        height: 45px;
        display: block;
        margin: 0 auto;
        margin-top: 10px;
        z-index: 99;
      }

      .promBox{
        width: 80%;
        /* border: 1px solid black; */
        font-family: rxtp;
        position: absolute;
        top: 50%;
        margin: 0 auto;
        color: white;
        text-align: left;
      }

      .promBox > .title{
        margin-top: 0px;
        margin-bottom: 10px;
        font-size: 22px;
        font-family: '思源黑体';
      }

      .promBox > p{
        margin-bottom: 0px;
        margin-top: 5px;
        font-size: 16px;
        font-family: '思源黑体';
      }
  
      .conBox{
        /* border: 1px solid white; */
        width: 70%;
        position: absolute;
        left: 15%;
        top: 10%;
        height: 30%;
        display: flex;
        justify-content: space-between;
        padding: 5px;
      }
  
      .codeBox{
        position: relative;
        width: 45%;
        height: 58%;
        transform: translate(0%,0%);
        /* border: 1px solid black; */
        text-align: center;
      }
  
      .codeBox > p{
        bottom: 10%;
        position: relative;
        color: white;
        font-family: Nutio;
      }
  
      .codeImg{
        position: relative;
        bottom: 15%;
        width: 110px;
        height: 68%;
        border-radius: 8%;
        padding: 5%;
        border: 1px solid rgba(255, 255, 255, 0.753);
      }
  
      .iconsBox{
        display: flex;
        justify-content: space-between;
        width: 10%;
        /* border: 1px solid black; */
        position: absolute;
        left: 45%;
        top: 12%;
        height: 4%;
      }
  
      .titlePic{
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 75%;
        position: relative;
        bottom: -25%;
        overflow: hidden;
        text-align: center;
        /* border: 1px solid black; */
      }
  
      .titlePic > img{
        transform: translate(-8%,-55%);
        max-width: 200px;
        max-height: 200px;
        flex: 1;
      }
  
      .titlePic > h2{
        position: absolute;
        color: white;
        top: 20%;
      }
  
      .titlePic > p{
        position: absolute;
        color: white;
        top: 45%;
        width: 90%;
        font-size: 12px;
        line-height: 20px;
        font-weight: normal;
      }
  
      .titlePic > .copys{
        position: absolute;
        color: white;
        top: 82%;
        width: 100%;
        font-size: 14px;
        font-weight: normal;
      }
  /* 
      .titlePic > .id{
        position: absolute;
        color: white;
        top: 44%;
        margin-bottom: 5%;
        width: 40%;
      }
  
      .titlePic > .id2{
        position: absolute;
        color: white;
        top: 48%;
        margin-bottom: 5%;
        width: 40%;
      } */
  
      .titlePic > .callMethods{
        position: absolute;
        color: white;
        top: 25%;
        font-size: 18px;
      }
  
      .titlePic > .idBox{
        /* border: 1px solid black; */
        display: flex;
        width: 300px;
        height: 6%;
        color: white;
        justify-items: center;
        justify-content: space-around;
        font-size: 12px;
        position: absolute;
        top: 92%;
      }
  
      .id:hover{
        color: rgb(213, 213, 213);
      }
  
      .id2:hover{
        color: rgb(213, 213, 213);
      }
  
  </style>