<template>
    <div id="container">
      <div class="chooseContainer">
        <div class="inline-blockStyle">
          <img class="imgCss" :src="iconImg"/>
          <p>{{ title }}</p>
          <img class="imgCss2" v-show="!$store.getters.hidePage && $router.currentRoute.path != '/share'" @click="updateIsShow" :src="chooseImg" />
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import IconImg from '../../assets/images/icons/icon4@1x.png';
  import ChooseImg from '../../assets/images/icons/choose@1x.png';
  
  export default {
    data() {
      return {
        iconImg: IconImg,
        chooseImg: ChooseImg,
        title: "iSPEAK"
      }
    },
    methods:{
      updateIsShow(){
        console.log(this.$store.getters.hidePage)
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
      }
    }
  }
  </script>
  
<style scoped>
  .chooseContainer {
    width: 90%;
    height: 20px;
    padding: 8px;
    position: absolute;
    left: 2%;
    transform: translate(0%,50%);
    /* border: 1px solid red; */
  }
  
  .imgCss {
    width: 35px;
    height: 35px;
    position: absolute;
    top: 10%;
  }

  .imgCss2{
    width: 35px;
    height: 35px;
    position: absolute;
    top: 15%;
    right: 0%;
    z-index: 25;
  }
  
  .inline-blockStyle > p {
    position: absolute;
    left: 15%;
    top: -5%;
    color: #000000;
    font-weight: bold;
  }
  
  .inline-blockStyle > img, .inline-blockStyle > p {
    display: inline-block;
  }
</style>
  