<template>
  <div class="footBox">
    <!-- 两个二维码容器部分 -->
    <!-- <div class="conBox">
      <div class="codeBox">
        <p style=" font-weight: bold;">{{ titles.title1 }}</p>
          <img class="codeImg" :src="imgs.codeImg1 || imgs.backgroundWhiteColor"/>
      </div>
      <div class="codeBox">
        <p>{{ titles.title2 }}</p>
          <img class="codeImg" :src="imgs.codeImg2 || imgs.backgroundWhiteColor"/>
      </div>
    </div> -->

    <!-- 微博、微信、in的icon图标位置 -->
    <!-- <div class="iconsBox">
      <img :src="imgs.icons[2]">
    </div> -->

    <!-- 背景图片iSPEAK -->
    <div class="titlePic">
      <p class="callMethods">{{ titles.callMethodsTxt }}</p>
      <p class="copys">Copyright © 2023 - 2024</p>
      <p class="addressInfo">深圳市有点智能信息技术有限公司</p>
      <div class="info_address_phoneBox">
        <p>公司简介：深圳市有点智能信息技术有限公司，是一家创办于2023年的教育科技公司。创始团队来自北京大学、复旦大学等一流名校，并拥有丰富的人工智能和互联网行业从业经验。团队致力于利用先进的人工智能技术，为用户打造创新的学习工具和解决方案。推动科技与教育的融合，助力实现知识、技能的普惠，帮助社会把握人工智能时代的机会与挑战。</p>
        <p class="address">地址：深圳市宝安区新安街道大浪社区新安三路一巷51号</p>
        <p class="phone">联系电话：0755-86716634</p>
      </div>
      
      <div class="idBox">
        <p class="id" @click="toPageUrl">{{ titles.id }}</p> 
        <p>|</p>
        <p class="id2" @click="toPageUrl">{{ titles.id2 }}</p>
      </div>
      <div class="recordBox">
        <img :src="imgs.recordIcon">
        <a href="https://beian.mps.gov.cn/#/query/webSearch?code=44030002003685" target="_blank" rel="noreferrer">粤公网安备44030002003685</a>
      </div>


    </div>

  </div>
</template>

<script>
import backgroundWhiteColor from '@/assets/images/icons/backgroundColorWhite.png'
import fIcon from '@/assets/images/icons/f_icon.png'
import inIcon from '@/assets/images/icons/in_icon.png'
import vxIcon from '@/assets/images/icons/vx_icon.png'
import wbIcon from '@/assets/images/icons/wb_icon.png' 
import titlePic from '@/assets/images/icons/iSpeakImg.png'
import titlePicGif from '@/assets/images/gifs/dinosaur-a.gif'
import recordIcon from '@/assets/images/icons/record_Icon.png'
export default {
  data(){
    return {
      titles:{
        title1: 'App Store',
        title2: '应用商店',
        callMethodsTxt: '欢迎关注i说英语微信公众号!',
        content: 'We are on a mission to make language learning irresistibly fun, accessible, and effective, ensuring every tap and swipe is a step towards a new world of words for your child.',
        content2: 'Copyright © 2023 - 2024 深圳市有点智能信息技术有限公司',
        id: "粤ICP备2024235969号-1",
        id2: '粤ICP备2024235969号-2A'
      },
      imgs:{
        codeImg1:'',
        codeImg2:'',
        backgroundWhiteColor: backgroundWhiteColor,
        titleImg: titlePic,
        titleImgGif: titlePicGif,
        icons:[fIcon,inIcon,vxIcon,wbIcon],
        recordIcon: recordIcon
      }
    }
  },
  methods:{
    toPageUrl(){
      window.location.href = 'https://beian.miit.gov.cn/'
    }
  }
}
</script>

<style scoped>
    .footBox{
      width: 100%;
      height: 100%;
      background-image: url('../../assets/images/icons/backgroundPic@1x.png');
      background-repeat: no-repeat;
      background-size: cover;
      position: relative;
    }

    .info_address_phoneBox{
      margin-top: 5%;
      top: 20%;
      position: absolute;
      color: white;
      width: 90%;
    }
    
    .info_address_phoneBox > p{
      text-align: left;
      font-size: 13px;
    }

    .conBox{
      /* border: 1px solid white; */
      width: 70%;
      position: absolute;
      left: 15%;
      top: 10%;
      height: 30%;
      display: flex;
      justify-content: space-between;
      padding: 5px;
    }

    .codeBox{
      position: relative;
      width: 45%;
      height: 58%;
      transform: translate(0%,0%);
      /* border: 1px solid black; */
      text-align: center;
    }

    .codeBox > p{
      bottom: 10%;
      position: relative;
      color: white;
      font-family: Nutio;
    }

    .codeImg{
      position: relative;
      bottom: 15%;
      width: 110px;
      height: 68%;
      border-radius: 8%;
      padding: 5%;
      border: 1px solid rgba(255, 255, 255, 0.753);
    }

    .iconsBox{
      display: flex;
      justify-content: space-between;
      width: 10%;
      /* border: 1px solid black; */
      position: absolute;
      left: 45%;
      top: 12%;
      height: 4%;
    }

    .titlePic{
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 80%;
      position: relative;
      top: 5%;
      overflow: hidden;
      text-align: center;
      /* border: 1px solid black; */
    }

    .titlePic > .addressInfo{
      position: absolute;
      top: 93%;
    }

    .titlePic > p{
      margin-bottom: 5px;
    }

    .titlePic > img{
      transform: translate(-8%,-55%);
      max-width: 200px;
      max-height: 200px;
      flex: 1;
    }

    .titlePic > .address{
      position: absolute;
      text-align: left;
      top: 55%;
      margin-bottom: 10px;
    }

    .titlePic > .info{
      position: absolute;
      color: white;
      top: 25%;
      text-align: left;
      margin-bottom: 10px;
    }

    .titlePic > .phone{
      position: absolute;
      top: 62%;
      text-align: left;
    }

    .titlePic > h2{
      position: absolute;
      color: white;
      top: 20%;
    }

    .titlePic > p{
      position: absolute;
      color: white;
      top: 25%;
      width: 90%;
      font-size: 12px;
      line-height: 20px;
      font-weight: normal;
    }

    .titlePic > .copys{
      position: absolute;
      color: white;
      top: 88%;
      width: 100%;
      font-size: 14px;
      font-weight: normal;
    }

    .recordBox{
      position: absolute;
      top: 80%;
      width: 220px;
      /* border: 1px solid black; */
      display: flex;
      justify-content: space-evenly;
    }

    .recordBox > img{
      width: 25px;
      height: 25px;
    }

    .recordBox > a{
      text-decoration: none;
      color: white;
      font-size: 12px;
      margin-top: 3px;
    }

/* 
    .titlePic > .id{
      position: absolute;
      color: white;
      top: 44%;
      margin-bottom: 5%;
      width: 40%;
    }

    .titlePic > .id2{
      position: absolute;
      color: white;
      top: 48%;
      margin-bottom: 5%;
      width: 40%;
    } */

    .titlePic > .callMethods{
      position: relative;
      color: white;
      top: -30%;
      font-size: 18px;
      margin-bottom: 10px;
    }

    .titlePic > .idBox{
      /* border: 1px solid black; */
      display: flex;
      width: 300px;
      height: 6%;
      color: white;
      justify-items: center;
      justify-content: space-around;
      font-size: 12px;
      position: absolute;
      top: 72%;
    }

    .id:hover{
      color: rgb(213, 213, 213);
    }

    .id2:hover{
      color: rgb(213, 213, 213);
    }

</style>