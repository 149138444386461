import Vue from 'vue'
import App from './App.vue'
import '../src/assets/styles/font.css'
import store from './store'
import router from './router'
import ElementUi from 'element-ui';
import { Message } from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import 'swiper/css/swiper.css'

Vue.use(ElementUi)
Vue.config.productionTip = false

new Vue({
  store,
  router,
  Message,
  render: function (h) { return h(App) }
}).$mount('#app')
