<template>
  <div class="tableBox">
    <table width="1200px">
        <tr>
            <td width="10%" align="right">
                <img class="img" :src="imas.IconImg" />
            </td>
            <td width="50%" align="left">
                <p class="title">iSPEAK</p>
            </td>
            <td class="txt" @click="toUrl('user_agrement')">使用协议</td>
            <td class="txt" @click="toUrl('privacy')">隐私政策</td>
            <td class="txt" @click="toUrl('kids_privacy')">儿童隐私政策</td>
        </tr>
    </table>
  </div>
</template>

<script>
import IconImg from '../../assets/images/icons/icon4@1x.png';
export default {
    data(){
        return {
            imas:{
                IconImg: IconImg
            },
            userAgreementUrl: 'https://www.ispeak.fun/user_agreement',
            privacyUrl: 'https://www.ispeak.fun/privacy',
            kids_privacyUrl: 'https://ispeak.fun/kids_privacy',   
        }
    },
    methods:{
        toUrl(urlPath){
            switch(urlPath){
                case 'user_agrement':
                    location.href = this.userAgreementUrl
                break;
                case 'privacy':
                    location.href = this.privacyUrl
                break;
                case 'kids_privacy':
                    location.href = this.kids_privacyUrl
                break;
            }
        },
    }
}
</script>

<style>
    .tableBox{
        width: 100%;
        position: relative;
        left: 50px;
    }

    .img{
        width: 45px;
        height: 45px;
    }

    .title{
        margin-left: 2%;
        font-family: Nuntio;
        font-size: 20px;
        font-weight: bold;
    }
    
    .txt{
        font-family: Nuntio;
        color: #19c472;
        font-weight: bold;
        font-size: 14px;
    }

    .txt:hover{
        cursor: pointer;
    }
</style>