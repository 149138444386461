<template>
    <div class="container">
        <div class="titleBox">
            <p class="bigTitle">{{ bigTitle }}</p>
            <p class="thinTitle">{{ thinTitle }}</p>
        </div>
        <div class="imgBox2">
            <img :src="imgList[0]"/>
        </div>
    </div>
</template>

<script>
import phoneAndIcon from '@/assets/images/icons/phoneAndIcon.png'
export default {
    data(){
        return {
            thinTitle: '随时随地，挑战英语',
            bigTitle: '趣味闯关模式',
            imgList:[phoneAndIcon],
        }
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: relative;
        top: -2%;
        text-align: center;
    }

    .bigTitle{
        width: 95%;
        margin-bottom: 5%;
        position: relative;
        left: 2%;
        font-size: 1.8em;
        font-weight: bold;
    }

    .thinTitle{
        width: 96%;
        position: relative;
        left: 2%;
        top: 10%;
        font-size: 1em;
    }

    .titleBox{
        width: 100%;
        position: absolute;
        top: 3%;
        /* border: 1px solid pink; */
    }

    .hidePage{
        position: fixed;
        top: 15%;right: 5%;
        z-index: 10;
    }

   /* 
   
   */

.imgBox2{
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
  /* border: 1px solid black; */
}

.imgBox2 > img{
  position: absolute;
  top: 0%;
  transform: translate(-52%,40%);
  width: 450px;
  height: 450px;
}
</style>
