<template>
  <div id="container">

    <div v-show="$store.getters.hidePage" class="isShowPage">
      <img :src="escImg" @click="updateIsShow" v-show="$store.getters.hidePage">
      <p @click="toPage('index')">首页</p>
      <p @click="toPage('user_agreement')">使用协议</p>
      <p @click="toPage('privacy')">隐私政策</p>
      <p @click="toPage('kids_privacy')">儿童隐私政策</p>
    </div>

    <!-- 第一个盒子高度限制30% -->
    <div class="header">
      <div class="choose">
        <Header></Header>
      </div>
    </div>

    <!-- 第二个主题展示盒子高度占40% -->
    <div class="main">
      <!-- <el-carousel :interval="4000" ref="slideCarousel" indicator-position="outside" height="580px">
        <el-carousel-item>
          <Main></Main>
        </el-carousel-item>
        <el-carousel-item>
          <Main2></Main2>
        </el-carousel-item>
        <el-carousel-item>
          <Main3></Main3>
        </el-carousel-item>
      </el-carousel> -->
      <div class="swiper-container" style="height: 580px;" ref="floorSwiper">
        <div class="swiper-wrapper">
            <div class="swiper-slide">
              <Main></Main>
            </div>
            <div class="swiper-slide">
              <Main2></Main2>
            </div>
            <div class="swiper-slide">
              <Main3></Main3>
            </div>
        </div>
      </div>
    </div>

    <!-- 底部盒子展示二维码占用比例30% -->
    <div class="footer">
      <Footer></Footer>
    </div>

    <div class="downLoad">
      <div class="downLoadBox">
        <img :src="iconDownLoad">
        <p class="downLoadTitle">i说英语</p>
        <p class="content">说好英语很简单</p>
        <button class="downLoadButton" @click="downLoadApp">免费下载</button>
      </div>
    </div>
  </div>
</template>

<script>
import HeaderTitle from '@/components/home/header_title.vue'
import Header from '@/components/home/header.vue';
import Main from '@/components/home/main.vue';
import Main2 from '@/components/home/main2.vue'
import Main3 from '@/components/home/main3.vue'
import Footer from '@/components/home/footer.vue';
import escImg from '@/assets/images/icons/x.png'
import iconDownLoad from '@/assets/images/icons/iconDownLoad.png'

// 引入js
import Swiper from "swiper";

export default {
  components:{
    HeaderTitle,Header,Main,Main2,Main3,Footer
  },
  data(){
    return {
      escImg: escImg,
      iconDownLoad: iconDownLoad,
      downLoadUrl: 'https://oss.ispeak.fun/download/android/ispeak-latest.apk', // apk下载地址
      userAgreementUrl: 'https://www.ispeak.fun/user_agreement',
      privacyUrl: 'https://www.ispeak.fun/privacy',
      kids_privacyUrl: 'https://ispeak.fun/kids_privacy',
      swiper: null
    }
  },
  watch:{
    list:{
      immediate: true,
      handler(){
        this.$nextTick(()=>{
          new Swiper(this.$refs.floorSwiper,{
            loop: true
          })
        })
      }
    }
  },
  methods:{
    updateIsShow(){
        // console.log(this.$store.getters.hidePage)
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
    },
    downLoadApp(){
      const agent = window.navigator.userAgent;
      const isIso = /iPad|iPhone/.test(agent)
      if(isIso){
        let a = document.createElement('a')
         a.href = "https://apps.apple.com/cn/app/i%E8%AF%B4%E8%8B%B1%E8%AF%AD/id6497169872"
         a.click();
      }else{
         let a = document.createElement('a')
         a.href = "https://oss.ispeak.fun/download/android/ispeak-latest.apk"
         a.click();
         // window.reload();
      }
    },
    toPage(path){
      if(path == 'index'){
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
        window.location.reload()
      }else if(path == 'user_agreement'){
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
        window.location.href = this.userAgreementUrl
      }else if(path == 'privacy'){
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
        window.location.href = this.privacyUrl
      }else if(path == 'kids_privacy'){
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
        window.location.href = this.kids_privacyUrl
      }
    },
    /*轮播手滑切换*/
    startAuto() {
      if (this.autoplay == false) {
        this.autoplay = true;
      }
    },
    stopAuto() {
      if (this.autoplay == true) {
        this.autoplay = false;
      }
    },
    // 走马灯滑动切换
    slideBanner() {
      //选中的轮播图
      var box = document.querySelector('.el-carousel__container');
      var startPointX = 0
      var stopPointX = 0
      //重置坐标
      var resetPoint = function () {
        startPointX = 0
        stopPointX = 0
      }
      //手指按下
      box.addEventListener("touchstart", function (e) {
        //手指点击位置的X坐标
        startPointX = e.changedTouches[0].pageX
      });
      //手指滑动
      box.addEventListener("touchmove", function (e) {
        //手指滑动后终点位置X的坐标
        stopPointX = e.changedTouches[0].pageX
      });
      //当手指抬起的时候，判断图片滚动离左右的距离
      let that = this
      box.addEventListener("touchend", function (e) {
        if (stopPointX == 0 || startPointX - stopPointX == 0) {
          resetPoint()
          return
        }
        if (startPointX - stopPointX > 0) {
          resetPoint()
          that.$refs.slideCarousel.next()
          return
        }
        if (startPointX - stopPointX < 0) {
          resetPoint()
          that.$refs.slideCarousel.prev()
          return
        }
      });
    }
  }
}
</script>

<style scoped>
    ::v-deep .el-carousel__arrow{
      display: none;
    }

    #container{
     /* position: relative; */
     position: absolute;
     top: 0%;
     left: 0%;
     width: 100%;
     font-family: Nuntio;
    }

    .header{
      position: absolute;
      top: 0px;
      width: 100%;
      height: 80px;
      /* border: 1px solid black; */
    }

    .title{
      height: 60px;
      /* border: 1px solid yellow; */
    }

    .choose{
      height: 50px;
    }

    .isShowPage{
      width: 100%;
      height: 1400px;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 40;
      position: fixed;
    }

    .isShowPage > img{
      position: fixed;
      right: 4.5%;
      top: 2.6%;
      width: 35px;
      height: 35px;
    }

    .isShowPage > p{
      color: white;
      text-align: right;
      position: relative;
      right: 5%;
      top: 5%;
      margin-bottom: 5%;
      margin-top: 5%;
      font-size: 20px;
    }

    .main{
      position: absolute;
      left: 0px;top: 80px;
      width: 100%;
      height: 600px;
      /* border: 1px solid red; */
      z-index: 0;
    }

    .footer{
      position: absolute;
      top: 520px;
      width: 100%;
      height: 700px;
      /* border: 1px solid black; */
      z-index: 1;
      background-image: url('../../assets/images/icons/backgroundPic@1x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .downLoad{
      width: 100%;
      height: 100px;
      bottom: 0%;
      /* border: 1px solid black; */
      background-color: white;
      position: fixed;
      z-index: 30;
    }

    .downLoadBox{
      width: 90%;
      height: 80%;
      position: relative;
      top: 10%;
      left: 5%;
      /* border: 1px solid black; */
    }

    .downLoadBox > img{
      width: 65px;
      height: 65px;
      transform: translate(30%,12%);
      border-radius: 15%;
    }

    .downLoadBox > .downLoadTitle{
      position: absolute;
      top: -12%;
      left: 32%;
      font-size: 20px;
      color: #1AA662;
    }

    .downLoadBox > .content{
      position: absolute;
      top: 35%;
      left: 32%;
    }

    .downLoadButton{
      position: absolute;
      top: 25%;
      right: 2%;
      background-color: #1AA662;
      border: none;
      border-radius: 10px 50px / 50px 20px;
      width: 25%;
      height: 50%;
      color: white;
    }
</style>