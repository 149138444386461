<template>
  <div class="titleBox">
    <div class="font">
        {{ title }}
    </div>
  </div>
</template>

<script>
export default {
    data(){
        return {
            title: 'i说英语官方网站'
        }
    }
}
</script>

<style scoped>
    .titleBox{
        width: 100%;
        height: 100%;
        background-color: #1AA662;
        position: relative;
        display: flex;
        justify-content: center;
        justify-items: center;
    }

    .font{
        position: absolute;
        bottom: 10%;
        transform: translateX(-2%);
        color: white;
    }

</style>