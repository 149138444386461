<template>
    <div class="container">
        <div class="titleBox">
            <p class="bigTitle">{{ bigTitle }}</p>
            <p class="thinTitle">{{ thinTitle }}</p>
        </div>
        <div class='imgBox'>
            <img :src="imgList[0]"/>
        </div>
    </div>
</template>

<script>
import abcImg from '../../assets/images/icons/abc@1x.png'
import phoneImg from '@/assets/images/icons/phone.png'
import phoneAndIcon from '@/assets/images/icons/phoneAndIcon.png'
export default {
    data(){
        return {
            bigTitle: 'i说英语，从现在开始!',
            thinTitle: '和我们一起，完成各种挑战，不断提高口语表达能力。轻松说好英语，就是这么简单！',
            imgList:[abcImg,phoneAndIcon,phoneImg],
        }
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: relative;
        top: -2%;
        text-align: center;
    }

    .bigTitle{
        /* border: 1px solid black; */
        width: 95%;
        margin-bottom: 5%;
        position: relative;
        left: 2%;
        font-size: 1.8em;
        font-weight: bold;
    }

    .thinTitle{
        width: 95%;
        position: relative;
        left: 2%;
        /* top: 50%; */
        font-size: 1em;
    }

    .titleBox{
        width: 100%;
        height: 35%;
        position: relative;
        top: 2%;
        /* border: 1px solid pink; */
    }

    .hidePage{
        position: fixed;
        top: 15%;right: 5%;
        z-index: 10;
    }

   /* 
   
   */
.imgBox {
  width: 100%;
  height: 55%;
  overflow: hidden;
  position: relative;
}

.imgBox > img {
  position: absolute;
  top: 0%;
  transform: translate(-52%);
  width: 500px;
  height: 400px;
}

.imgBox2{
  width: 100%;
  height: 55%;
  overflow: hidden;
  position: relative;
  animation: fadeInOut 8s infinite; /* 添加动画效果 */
}

.imgBox2 > img{
  position: absolute;
  top: 0%;
  transform: translate(-52%);
  width: 450px;
  height: 450px;
}

.imgBox3{
  width: 100%;
  height: 55%;
  overflow: hidden;
  position: relative;
  animation: fadeInOut 8s infinite; /* 添加动画效果 */
}

.imgBox3 > img{
  position: absolute;
  top: 5%;
  transform: translate(-52%);
  width: 600px;
  height: 500px;
}
</style>
