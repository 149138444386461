import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    hidePage: false
  },
  getters: {
    hidePage(state){
      return state.hidePage;
    }
  },
  mutations: {
    // isShow? true | false
    UPDATESHOWPAGE(state,flag){
      state.hidePage = flag;
    }
  },
  actions: {
    updateShowPage(context,flag){
      context.commit('UPDATESHOWPAGE',flag)
    }
  }
})
