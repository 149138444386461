<template>
  <div class="container">
    <div class="back"></div>

    <!-- 第二个主题展示盒子高度占40% -->
    <div class="main">
      <T_Main></T_Main>
    </div>

    <!-- 底部盒子展示二维码占用比例30% -->
    <div class="footer">
      <T_Footer :inviter_id="inviterId"></T_Footer>
    </div>

 </div>
</template>

<script>
import Header from '@/components/home/header.vue'
import T_Main from '@/components/templates/t_main.vue'
import T_Footer from '@/components/templates/t_footer.vue'
import escImg from '@/assets/images/icons/x.png'
import iconDownLoad from '@/assets/images/icons/iconDownLoad.png'
import backTwoImg from '@/assets/images/icons/backFooter.png'
export default {
    data(){
      return {
        escImg: escImg,
        iconDownLoad: iconDownLoad,
        downLoadUrl: 'https://oss.ispeak.fun/apk/ISPEAK.apk', // apk下载地址
        backTwoImg: backTwoImg,
        userAgreementUrl: 'https://www.ispeak.fun/user_agreement',
        privacyUrl: 'https://www.ispeak.fun/privacy',
        kids_privacyUrl: 'https://ispeak.fun/kids_privacy',
        inviterId: ''
      }
    },
    components:{
        Header,T_Main,T_Footer
    },
    mounted(){
      this.inviterId = this.getUrlKey('inviter_id')
    },
    methods:{
      updateIsShow(){
        // console.log(this.$store.getters.hidePage)
        this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
      },
      getUrlKey: function (name) {
        return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [, ""])[1].replace(/\+/g, '%20')) || null
      },
      downLoadApp(){
        const agent = window.navigator.userAgent;
        const isIso = /iPad|iPhone/.test(agent)
        if(isIso){

        }else{
          
        }
      },
      toPage(path){
        if(path == 'index'){
          this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
          this.$router.push({name:'home'})
        }else if(path == 'user_agreement'){
          this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
          window.location.href = this.userAgreementUrl
        }else if(path == 'privacy'){
          this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
          window.location.href = this.privacyUrl
        }else if(path == 'kids_privacy'){
          this.$store.dispatch('updateShowPage',!this.$store.getters.hidePage)
          window.location.href = this.kids_privacyUrl
        }
      }
  }
}
</script>

<style scoped>
    #container{
     /* position: relative; */
     position: absolute;
     top: 0%;
     left: 0%;
     /* border: 1px solid black; */
     width: 100%;
     font-family: Nuntio;
    }

    .back{
      width: 100%;
      height: 400px;
      position: absolute;
      top: 0px;left: 0%;
      background-image: url('../../assets/images/icons/backgroundImg.png');
      background-size: cover;
      /* border: 1px solid black; */
    }

    .header{
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 80px;
      /* border: 1px solid black; */
    }

    .title{
      height: 60px;
      /* border: 1px solid yellow; */
    }

    .choose{
      height: 50px;
    }

    .isShowPage{
      left: 0%;
      top: 0%;
      width: 100%;
      height: 1400px;
      background-color: rgba(0, 0, 0, 0.8);
      z-index: 100;
      position: fixed;
    }

    .isShowPage > img{
      position: fixed;
      right: 4.5%;
      top: 2.6%;
      width: 35px;
      height: 35px;
    }

    .isShowPage > p{
      color: white;
      text-align: right;
      position: relative;
      right: 5%;
      top: 5%;
      margin-bottom: 5%;
      margin-top: 5%;
      font-size: 20px;
    }

    .main{
      position: absolute;
      left: 0px;top: 50px;
      width: 100%;
      height: 600px;
      /* z-index: 80; */
      /* border: 1px solid red; */
    }

    .footer{
      position: absolute;
      top: 400px;
      left: 0%;
      width: 100%;
      height: 800px;
      /* border: 1px solid black; */
      z-index: 1;
      background-image: url('../../assets/images/icons/backgroundPic@1x.png');
      background-repeat: no-repeat;
      background-size: cover;
    }

    .downLoad{
      width: 100%;
      height: 100px;
      bottom: 0%;
      /* border: 1px solid black; */
      background-color: white;
      position: fixed;
      z-index: 30;
    }

    .downLoadBox{
      width: 90%;
      height: 80%;
      position: relative;
      top: 10%;
      left: 5%;
      /* border: 1px solid black; */
    }

    .downLoadBox > img{
      width: 65px;
      height: 65px;
      transform: translate(30%,12%);
      border-radius: 15%;
    }

    .downLoadBox > .downLoadTitle{
      position: absolute;
      top: -12%;
      left: 32%;
      font-size: 20px;
      color: #1AA662;
    }

    .downLoadBox > .content{
      position: absolute;
      top: 35%;
      left: 32%;
    }

    .downLoadButton{
      position: absolute;
      top: 25%;
      right: 2%;
      background-color: #1AA662;
      border: none;
      border-radius: 10px 50px / 50px 20px;
      width: 25%;
      height: 50%;
      color: white;
    }
</style>