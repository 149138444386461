<template>
    <div class="container">
        <div class="titleBox">
                <p class="titleTxt">i说英语</p>
                <p class="titleTxt2">孩子学英语的好助手</p>
                <p class="contentTxt">24小时AI私教，权威语音模型 助力孩子轻松提升英语水平!</p>
        </div>

        <div class='imgBox'>
            <img :src="imgList[2]"/>
            <img class="pic2" :src="imgList[0]"/>
        </div>

    </div>
</template>

<script>
import phoneImg from '@/assets/images/icons/phone.png'
import phoneAndIcon from '@/assets/images/icons/phoneAndIcon.png'
import backFooterImg from '@/assets/images/icons/backFooter.png'
import axios from 'axios'
export default {
    data(){
        return {
            imgList:[backFooterImg,phoneAndIcon,phoneImg],
            inputs:{
                placeTxt: '输入手机号',
                phoneValue: ''
            }
        }
    },
    methods: {
        focusInput(){
            this.inputs.placeTxt = ''
        },
        checkTxtIsEmpty(){
            if(this.inputs.phoneValue == ''){
                this.inputs.placeTxt = '输入手机号'
            }
        },
        getPromission(){
            axios({
                url:'https://api.ispeak.fun/share/record_invitation',
                method: 'POST',
                headers:{
                    'Content-Type': 'application/json'
                },
                data: JSON.stringify({  
                    inviter_id: '664ef60bfdbfec0cdd67eabd',
                    invited_phone: this.inputs.phoneValue
                })
            }).then(res=>{
                const message = res.data.message;
                this.showMessage(message);
            })
        },
        showMessage(message){
            switch(message){
                case '该用户已被邀请':
                
                break;
                case '邀请成功':
                
                break;
            }
        }
    }
}
</script>

<style scoped>
    .container{
        width: 100%;
        height: 100%;
        position: relative;
        top: -2%;
        text-align: center;
        font-family: rtxp;
    }

    .titleTxt{
        font-size: 2.3em;
        font-weight: bold;
    }

    .titleTxt2{
        font-size: 1.6em;
        color: #19c472;
    }

    .titleBox{
        position: relative;
        /* border: 1px solid black; */
        width: 80%;
        top: 5%;
        left: 10%;
        z-index: 40;
    }

    .titleBox > p{
        margin-bottom: 5px;
        margin-top: 0px;
    }

    .titleBox > .contentTxt{
        width: 54%;
        position: relative;
        left: 23%;
        color: #a5adab;
        font-size: 0.8em;
    }

    .formBox{
        width: 80%;
        /* border: 1px solid black; */
        position: relative;
        left: 10%;
        top: 17%;    
        z-index: 50;
    }

    .formBox > p{
        font-size: 16px;
        margin-bottom: 20px;
    }

    .formBox > input{
        width: 80%;
        padding: 10px;
        border-radius: 15px;
        margin-bottom: 20px;
        border: 1px solid white;
        font-size: 14px;
    }

    .formBox button{
        width: 70%;
        padding: 10px;
        border-radius: 15px;
        margin-bottom: 20px;
        border: 1px solid blue;
        background-color: blue;
        color: white;
    }

    .promBox{
        position: relative;
        top: 20%;
        z-index: 50;
        color: white;
        width: 90%;
        /* border: 1px solid white; */
        left: 5%;
        font-size: 14px;
        justify-items: start;
        justify-content: start;
        text-align: left;
    }

    .imgBox {
      width: 100%;
      height: 100%;
      overflow: hidden;
      position: absolute;
      /* top: -35%; */
      bottom: 22%;
    }

    .imgBox > img {
      position: absolute;
      top: 55%;
      transform: translate(-52%);
      max-width: 350px;
      max-height: 400px;
    }

    .imgBox > .pic2{
      position: absolute;
      top: 50%;
      transform: translate(-65%);
      max-width: 1000px;
      z-index: -1;
    }
</style>
